import { useState, useEffect, useRef } from "react";
import {
  faClose,
  faCircleCheck,
  faDownload,
  faFileCircleXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LoadingPagey from "../../components/LoadingPagey";

const TERRAFORMATION_CHECK_INTERVAL = 3000;
const DOWNLOAD_BUTTON_TEXT = "Download Terraform Code";

/** @param {Object} props
 * @param {string} props.terraformationRunId
 * @param {import("./index").GetTerraformationStatus} props.getTerraformationStatus
 * @param {import("./index").GetTerraformationLogs} props.getTerraformationLogs
 * @param {import("./index").DownloadTerraformCode} props.downloadTerraformCode
 */
export const TerraformationProgress = ({
  terraformationRunId,
  getTerraformationStatus,
  getTerraformationLogs,
  downloadTerraformCode,
}) => {
  /** @type {[import("./index").TerraformationStatus, function(string): void]} */
  const [terraformationStatus, setTerraformationStatus] = useState("pending");
  const [terraformationLogs, setTerraformationLogs] = useState("");
  const [showTerraformationLogs, setShowTerraformationLogs] = useState(false);
  const [showTerraformInstructions, setShowTerraformInstructions] =
    useState(false);
  const [downloadPending, setDownloadPending] = useState(false);
  const logsContainer = useRef(null);

  const decorateLogs = (txt) => {
    let content = txt;
    try {
      content = content.replace(/\033\[0m/g, "</span>");
      content = content.replace(/\033\[31m/g, '<span class="red">');
      content = content.replace(/\033\[32m/g, '<span class="green">');
      content = content.replace(/\033\[33m/g, '<span class="yellow">');
      content = content.replace(/\033\[90m/g, '<span class="gray">');
      content = content.replace(/\033\[1m/g, '<span class="bold">');

    } catch (e) {
      console.error({ TerraformationProgress_decorateLogs_error: e });
    }
    return content;
  };

  const toggleShowTerraformationLogs = () =>
    setShowTerraformationLogs(!showTerraformationLogs);

  useEffect(() => {
    if (logsContainer == null || logsContainer.current == null) return;
    logsContainer.current.scrollTop = logsContainer.current.scrollHeight;
  }, [terraformationLogs.length]);

  useEffect(() => {
    const checksInterval = setInterval(async () => {
      const status = await getTerraformationStatus(terraformationRunId);
      const logs = await getTerraformationLogs(terraformationRunId);

      setTerraformationStatus(status);
      setTerraformationLogs(decorateLogs(logs));
    }, TERRAFORMATION_CHECK_INTERVAL);

    if (terraformationStatus !== "pending") {
      clearInterval(checksInterval);
    }

    return () => {
      clearInterval(checksInterval);
    };
  }, [terraformationStatus]);

  return (
    <div className="tfer-app__terraformation-progress-container">
      <div className="tfer-app__plan-btn-container">
        <button
          className="tfer-app__btn tfer-app__btn--download-tf"
          disabled={downloadPending || terraformationStatus !== "ok"}
          onClick={async () => {
            setDownloadPending(true);
            setShowTerraformationLogs(false);
            setShowTerraformInstructions(true);
            await downloadTerraformCode(terraformationRunId);
            setDownloadPending(false);
          }}
        >
          <FontAwesomeIcon icon={faDownload} style={{ marginRight: 10 }} />
          {DOWNLOAD_BUTTON_TEXT}
        </button>
      </div>

      {showTerraformInstructions ? (
        <div className="tfer-app__terrafor-instructions">
          <p>Next steps:</p>
          <ol>
            <li className="text-unfeatured">
              (Optional) Set up a git repository to track the state of your
              terraformed PagerDuty resources and to manage them using
              restorable versions{/* following <a href="#">this guide</a> */}.
            </li>
            <li>
              Ensure to have Terraform installed on your local machine. (How to
              install{" "}
              <a
                target="_blank"
                href="https://developer.hashicorp.com/terraform/install?product_intent=terraform"
              >
                here
              </a>
              .)
            </li>
            <li>
              Extract the results from the{" "}
              <code className="tfer-keyword">.zip</code> archive you just
              downloaded.
            </li>
            <li>
              Open a terminal and navigate to the directory where you unzipped
              the terraformation output file.
            </li>
            <li>
              Run the command <code>terraform init</code> to initialize the
              terraform configuration.
            </li>
            <li>
              Run the command <code>terraform plan</code> to see the PagerDuty
              configurations you will start to manage with the just downloaded
              terraform configuration.
            </li>
            <li>
              Run the command <code>terraform apply</code> to start managing the
              PagerDuty configurations through the terraform configuration.
            </li>
          </ol>
          <br />
          <p>
            For more information on PagerDuty Terraform Provider, please visit:{" "}
            <a
              target="_blank"
              href="https://registry.terraform.io/providers/PagerDuty/pagerduty/latest/docs"
            >
              https://registry.terraform.io/providers/PagerDuty/pagerduty/latest/docs
            </a>
          </p>
        </div>
      ) : (
        <div className="tfer-app__terraformation-progress-status">
          {terraformationStatus === "pending" && (
            <div
              className="tfer-app__terraformation-progress-loading-pagey"
              onClick={toggleShowTerraformationLogs}
            >
              <div className="tfer-app__terraformation-progress-loading-wrapper">
                <LoadingPagey />
              </div>
            </div>
          )}

          {terraformationStatus === "ok" && (
            <div className="tfer-app__terraformation-progress-ok">
              <FontAwesomeIcon icon={faCircleCheck} />
              <div>Terraformation completed.</div>
            </div>
          )}
          {terraformationStatus === "failed" && (
            <div className="tfer-app__terraformation-progress-failed">
              <FontAwesomeIcon icon={faFileCircleXmark} />
              <div>Terraformation failed.</div>
            </div>
          )}
        </div>
      )}

      {!showTerraformationLogs && (
        <div className="tfer-app__terraformation-progress-show">
          <small
            className="text-unfeatured tfer-app__terraformation-progress-loading-caption"
            onClick={toggleShowTerraformationLogs}
          >
            Click here to see detailed information of the terraformation
            process.
          </small>
        </div>
      )}

      {showTerraformationLogs && (
        <div className="tfer-app__logs-container">
          <div
            className="tfer-app__logs-container-pre-close"
            onClick={toggleShowTerraformationLogs}
          >
            <FontAwesomeIcon
              icon={faClose}
              style={{ width: "24px", height: "24px" }}
              title="Close terraformation logs"
            />
          </div>
          <pre ref={logsContainer} className="tfer-app__logs-container-pre">
            {terraformationLogs === "" && (
              <code className="tfer-app--logs-pending">
                Waiting for terraformation output logs...
              </code>
            )}
            {terraformationLogs !== "" && (
              <code
                className="tfer-app__logs-output"
                dangerouslySetInnerHTML={{ __html: terraformationLogs }}
              ></code>
            )}
            {terraformationStatus == "ok" && (
              <code className="tfer-app__logs-output">
                <br />
                Terraformation completed. Configuration code is ready to
                download.
                <br />
                Please scroll to the top of this page and click the button "
                <b>{DOWNLOAD_BUTTON_TEXT}</b>" to get the code.
              </code>
            )}
          </pre>
        </div>
      )}
    </div>
  );
};

import styled from "styled-components";

export const MenuContainer = styled.div`
  background-color: white;
  height: 100vh;
  box-shadow: 1px 1px 2px 0px #88888840;
  transition: 0.2s;
  color: rgb(108, 117, 125);
  display: flex;
  flex-direction: column;
  z-index: 10
`;

export const MenuSubMenu = styled.div`
  position: absolute;
  left: 60px;
  width: 200px;
  display: none;
  box-shadow: 0px 3px 3px 1px #8888881f;
  background-color: white;
`;

export const LogoIMG = styled.img`
  height: 30px;
`;

export const LogoIMGFull = styled.img`
  height: 30px;
  display: block;
  overflow: hidden;
`;

export const MenuIconContainer = styled.div.attrs(({ selected }) => ({
  "data-active": selected,
}))`
  color: ${({ selected }) => (selected ? "#06ac38" : "rgb(108,117,125)")};
  width: 60px;
  height: 60px;
  display: inline-block;
  cursor: pointer;
  position: relative;
  overflow: hidden;
`;

export const MenuIconIcon = styled.div`
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const MenuSubMenuFirstLabel = styled.div`
  width: 160px;
  height: 60px;
  padding: 0px 20px;
  display: flex;
  align-items: center;
  font-size: 18px;
  z-index: 100
`;

export const MenuLabel = styled.div.attrs(({ selected }) => ({
  "data-active": selected,
}))`
  color: ${({ selected }) => (selected ? "#06ac38" : "rgb(108,117,125)")};
  height: 40px;
  padding: 0px 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-bottom: ${({ selected }) =>
    selected ? "1px solid #06ac38" : "1px solid rgb(108 117 125 / 17%)"};
  &:hover {
    border-bottom: 1px solid #06ac38;
    color: #06ac38
  }
`;

export const MenuIcon = styled.div`
  height: 60px;
  &:hover {
    ${MenuIconContainer} {
      z-index:100;
      color: #06ac38;
      width: 260px;
      box-shadow: inset 0 -6px 3px -6px #83838340,
        inset 0 6px 3px -6px #83838340, inset 0 0px 3px 1px #83838340;
      display: flex;
      background-clip: border-box;
      background-color: #ffffff;
      overflow-x: visible;
    }
    ${MenuSubMenu} {
      z-index:100
      pointer-events: all;
      display: block;
    }
  }
`;

export const BigMenuContainer = styled.div``;

export const BigMenuIconContainer = styled.div`
  display: flex;
  margin: 15px;
  flex-direction: column;
  cursor: pointer;
`;

export const BigMenuIconContainerInner = styled.div.attrs(({ selected }) => ({
  "data-active": selected,
}))`
  color: ${({ selected }) => (selected ? "#06ac38" : "rgb(108,117,125)")};
  display: flex;
  text-align: left;
  width: 160px;
`;

export const BigMenuTaskContainer = styled.div`
  display: flex;
`;
export const BigBottomMenuTaskContainer = styled.div`
  display: flex;
  margin: 15px;
  cursor: pointer;
`;

export const BigMenuIconContainerInnerIcon = styled.div`
  margin-right: 15px;
`;

export const BigMenuIconContainerInnerLabel = styled.div`
  &:hover {
    color: #08ac38;
  }
`;

export const BigMenuChevronContainer = styled.button.attrs(({ selected }) => ({
  "data-active": selected,
}))`
  width: 50px;
  display: flex;
  background-color: white;
  justify-content: center;
  align-items: center;
  outline: none;
  border: none;
  cursor: pointer;
  color: ${({ selected }) => (selected ? "#06ac38" : "rgb(108,117,125)")};
  svg {
    transform: ${({ selected }) => (selected ? "rotateZ(90deg)" : "none")};
  }
`;
export const BigMenuSubtaskContainer = styled.div.attrs(({ selected }) => ({
  "data-active": selected,
}))`
  width: 190px;
  align-self: flex-start;
  border: none;
  padding-left: 14px;
  font-size: 14px;
  display: ${({ selected }) => (selected ? "block" : "none")};
`;

export const BigMenuIconSectionTitle = styled.div`
  padding: 20px 15px 0px 15px;
  text-transform: uppercase;
`;

export const BigMenuLabel = styled.div.attrs(({ selected }) => ({
  "data-active": selected,
}))`
  color: ${({ selected }) => (selected ? "#06ac38" : "rgb(108,117,125)")};
  width: 100%;
  width: calc(100% - 20px);
  padding: 15px 20px 0px 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    color: #06ac38;
  }
`;

export const MenuLogoutContainer = styled.div`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: auto;
`;

export const BigBottomMenu = styled.div`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: auto;
`;

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  cursor: pointer;
`;

export const LogoutLabel = styled.div.attrs(({ menuOpen }) => ({
  "data-active": menuOpen,
}))`
  display: ${({ menuOpen }) => (menuOpen ? "flex" : "none")};
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  font-size: 20px;
`;

export const FeedbackLabel = styled.div.attrs(({ menuOpen }) => ({
  "data-active": menuOpen,
}))`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  font-size: 20px;
  padding-left: 10px;
`;

export const MenuLogoutButton = styled.button.attrs(({ menuOpen }) => ({
  "data-active": menuOpen,
}))`
  width: calc(100% - 20px);
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  color: rgb(108, 117, 125);
  border: none;
  cursor: pointer;
  background-color: transparent;

  label {
    display: none;
    cursor: pointer;
  }

  svg {
    font-size: 20px;
    padding: 10px;
  }
  &:hover {
    background-color: rgb(6, 172, 56);
    color: white;
    border-radius: ${({ menuOpen }) => (menuOpen ? "5px" : "50%")};
  }
`;

export const FeedbackIcon = styled.button.attrs(({ menuOpen }) => ({
  "data-active": menuOpen,
}))`
  width: calc(100% - 20px);
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  color: rgb(108, 117, 125);
  border: none;
  cursor: pointer;
  background-color: transparent;

  label {
    display: none;
    cursor: pointer;
  }

  svg {
    font-size: 20px;
    padding: 10px;
  }
  &:hover {
    background-color: rgb(6, 172, 56);
    color: white;
    border-radius: ${({ menuOpen }) => (menuOpen ? "5px" : "50%")};
  }
`;

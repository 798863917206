
const Paths = {
  Base: "/",
  Login: "/login",
  Logout: "/logout",
  Unauthorized: "/unauthorized",
  Validate: "/validate",
  ValidateOauth: "/validate/oauth2",
  Home: "/home",
  Profile: "/profile",
  MigrationSummary: "/migration-summary",
  AvailableMigrations: "/available-migrations",
  Migration: "/migration",
  MigrationExplore: "/migration-explore",
  CompareMigration: "/migration-compare",
  MigrationWizard: "/migration-wizard",
  MigrationTest:"/migration-visualization",
  Users: "/admin",
  IntroAdministration: "/intro-administration",
  HealthCheck: "/health-check",
  HealthCheckClient: "/health-check-client",
  HealthCheckDataViewer: "/health-check-data-viewer",
  OnBoarding: "/onboarding",
  PsStatus: "/ps-status",
  PDUApp: "/pd-university",
  SuccessOnDemand: "/success-on-demand",
  SelectClient: "/select-client",
  FAQ: "/faq",
  AddUser: "/user/add",
  EditUser: "/user/:id",
  DeckGenerator: "/deck-generator",
  SolutionGalleryNoId: "/solution-gallery",
  SolutionGallery: "/solution-gallery/:id?",
  SolutionSubCategory: "/solution-sub-category",
  NewsAdministration: "/alerts-administration",
  MyAlertsApp: "/my-alerts-app",
  Authorization: "/authorization",
  Dashboard: "/dashboard",
  CustomerSnapshots: "/customer-snapshots",
  Dashboard: "/dashboard",
  Terrafomer: "/terraformer",
};

export default Paths;
import { useState, useEffect } from "react";
import MultiSelect from "../../components/MultiSelect";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

/**
 * @param {Object} props
 * @params {import("./index").TFerReducerState} props.tferState
 * @params {import("./index").AccountPickerOnSubmit} props.onSubmit
 * @params {boolean} props.hasRESTAPIKey
 * @params {string} props.validationError
 * @params {Function} props.onChangeClient
 * @params {Function} props.onChangeSubdomain
 */
export const AccountPicker = ({
  tferState,
  onSubmit,
  hasRESTAPIKey,
  validationError,
  onChangeClient,
  onChangeSubdomain,
}) => {
  const [apiCredential, setAPICredential] = useState("");
  const [subdomain, setSubdomain] = useState("");
  const [mergedSubdomains, setMergedSubdomains] = useState({});
  const [selectedClient, setSelectedClient] = useState("");

  const mergedClients = {
    data: [
      // ...tferState.clients.data, // Not available during EA phase
      ...tferState.customers.data.map((c) => ({
        salesforce_id: c.customer_subdomain,
        customer_company_name: c.customer_company_name,
      })),
    ]
      .filter((client, index, self) =>
        index === self.findIndex((t) =>
          t.customer_company_name === client.customer_company_name
        )
      )
      .sort((a, b) =>
        a.customer_company_name.localeCompare(b.customer_company_name),
      ),
  };

  useEffect(() => {
    const merged = {
      ...tferState.clientsSubdomains,
      ...tferState.customers.data.reduce((result, c) => {
        result[c.customer_subdomain] = {
          status: "success",
          error: null,
          data: [
            {
              subdomain_name: c.customer_subdomain,
            },
          ],
        };
        return result;
      }, {}),
    };
    setMergedSubdomains(merged);
  }, [tferState.clientsSubdomains]);

  return (
    <div className="tfer-app__account-picker">
      <div className="tfer-app__line">
        <div>
          {tferState.isPremiumTerraformer ? (
            <PremiumCustomerPicker
              clients={mergedClients}
              setSelectedClient={setSelectedClient}
              clientsSubdomains={mergedSubdomains}
              onChangeClient={(v) => {
                onChangeClient(v);
                setSubdomain("");
              }}
              subdomain={subdomain}
              onChangeSubdomain={(v) => {
                setSubdomain(v);
                onChangeSubdomain(v);
              }}
            />
          ) : (
            <CustomerPicker
              customersList={tferState.customers.data}
              customerSubdomain={subdomain}
              setCustomerSubdomain={(v) => {
                setSubdomain(v);
                onChangeSubdomain(v);
              }}
              onSetCustomer={onChangeSubdomain}
            />
          )}
        </div>
      </div>
      {subdomain &&
        hasRESTAPIKey === false /* important as false !== null */ && (
          <div className="tfer-app__line">
            <p className="tfer-app__separated">
              We don't have API credentials for this account, please upload one: {
                validationError && (<span style={{ color: "red" }}><strong>{validationError}</strong></span>)
              }
            </p>
            <div className="InputContainer">
              <div className="InputContainerInner">
                <input
                  type="password"
                  className="InputInput"
                  placeholder="Please insert an API key"
                  value={apiCredential}
                  onChange={(ev) => {
                    setAPICredential(ev.currentTarget.value);
                  }}
                />
              </div>
              <div className="Flex">
                <div
                  className="InputIcon1"
                  onClick={() => setAPICredential("")}
                >
                  <FontAwesomeIcon icon={faXmark} />
                </div>
              </div>
            </div>
            <p className="text-align-right tfer-app__caption">
              Using a <b>read-only</b> API key is recommended.
            </p>
          </div>
        )}
      <div className="tfer-app__line" style={{ textAlign: "right" }}>
        <button
          className="tfer-app__btn tfer-app__btn--featured"
          onClick={() => onSubmit(apiCredential, subdomain, selectedClient)}
          disabled={hasRESTAPIKey || apiCredential ? "" : "disabled"}
        >
          Continue
        </button>
      </div>
    </div>
  );
};

/**
 * @param {Object} props
 */
export const CustomerPicker = ({
  customerSubdomain,
  setCustomerSubdomain,
  customersList,
  onSetCustomer,
}) => {
  const options = customersList.map((obj) => ({
    ...obj,
    valueToDisplay: `${obj.customer_company_name} (${obj.customer_subdomain})`,
  }));
  return (
    <div>
      <div className="tfer-app__line">
        <div>
          <MultiSelect
            allowOnlyOne
            options={options}
            onChange={(obj) => {
              if (obj[0] == null) {
                setCustomerSubdomain("");
              } else {
                setCustomerSubdomain(obj[0].customer_subdomain);
                onSetCustomer(obj[0].customer_subdomain);
              }
            }}
            placeholder="Please select a customer"
            valueToDisplay="valueToDisplay"
            style={{ flexGrow: 1 }}
            type={customerSubdomain === "" ? "" : "hide"}
          />
        </div>
      </div>
    </div>
  );
};

/**
 * @param {Object} props
 * @params {import("./index").AsyncAction<Client[]>} props.clients
 */
export const PremiumCustomerPicker = ({
  clients,
  setSelectedClient,
  clientsSubdomains,
  onChangeClient,
  subdomain,
  onChangeSubdomain,
}) => {
  const [clientID, setClientID] = useState("");

  const client = clients.data.find((c) => c.salesforce_id === clientID);
  const selectedSubdomains = clientsSubdomains[clientID];

  const memo = {};
  const subdomainOptions = (selectedSubdomains?.data ?? [])
    .map((v) => {
      if (memo[v.subdomain_name] == null) {
        memo[v.subdomain_name] = true;
        return {
          id: v.subdomain_name,
          label: v.subdomain_name,
        };
      }
    })
    .filter((x) => x);

  useEffect(() => {
    if (subdomain == "" && subdomainOptions.length === 1) {
      onChangeSubdomain(subdomainOptions[0].id);
    }
  }, [subdomain, subdomainOptions]);

  return (
    <div>
      <div className="tfer-app__line">
        <Picker
          options={clients.data.map((c) => ({
            id: c.salesforce_id,
            label: c.customer_company_name,
          }))}
          onSelect={(opt) => {
            onChangeSubdomain("");
            setClientID(opt.id);
            setSelectedClient(opt.label);
            onChangeClient({
              salesforce_id: opt.id,
              customer_company_name: opt.label,
            });
          }}
        >
          {client ? client.customer_company_name : "Select a client"}
        </Picker>
      </div>
      <div className="tfer-app__line">
        <Picker
          options={subdomainOptions}
          onSelect={(o) => {
            onChangeSubdomain(o.id);
          }}
          disabled={
            selectedSubdomains?.status !== "success" ||
            subdomainOptions.length === 1
          }
        >
          {(selectedSubdomains == null ||
            selectedSubdomains?.status === "success") &&
            (subdomain ? subdomain : "Select a subdomain")}
          {selectedSubdomains?.status === "pending" &&
            "Waiting for the subdomains of this client..."}
        </Picker>
      </div>
    </div>
  );
};

/**
 * @param {Object} props
 * @params {import("./index").AsyncAction<Optionable[]>} props.options
 */
export const Picker = ({ options, onSelect, children, disabled }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [isOpenDropdown, setIsOpenDropdown] = useState(false);
  const filterOptions = options.filter((o) =>
    o.label?.toLowerCase().includes(searchTerm.toLowerCase()),
  );
  return (
    <div>
      <button
        className={`dropdown-toggleButton ${isOpenDropdown ? "is-open" : ""}`}
        onClick={() => setIsOpenDropdown(!isOpenDropdown)}
        disabled={disabled}
      >
        {children}
      </button>
      {isOpenDropdown && (
        <div className="dropdown-menuClient">
          <input
            className="search__clientsFilter"
            type="text"
            value={searchTerm}
            placeholder="Filter by name..." /* Generalize */
            onChange={(ev) => {
              setSearchTerm(ev.currentTarget.value);
            }}
          />
          <ul>
            {filterOptions.map((option) => (
              <li key={option.id}>
                <label>
                  <input
                    type="radio"
                    onChange={() => {
                      onSelect(option);
                      setIsOpenDropdown(false);
                      setSearchTerm("");
                    }}
                  />
                  <span className="customer_name">{option.label}</span>
                </label>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

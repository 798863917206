import { useState, useMemo } from "react";
import MultiCheckbox from "./MultiCheckbox";

const TITLE_TEXT = {
  services: "Technical services",
  servicesOrchestrations: "Services Orchestrations",
  servicesOrchestrationsCacheVariables: "Services Orchestrations Cache Variables",
  users: "Users",
  orchestrations: "Event Orchestrations",
  orchestrationsIntegrations: "Integrations for an Event Orchestration",
  orchestrationsGlobal: "Global Event Orchestrations",
  orchestrationsGlobalCacheVariables: "Global Event Orchestrations Cache Variables",
  orchestrationsRouter: "Event Orchestrations Routers",
  orchestrationsUnroutedRouter: "Event Orchestrations Unrouted Routers",
  orchestrationsServices: "Event Orchestrations Services",
  orchestrationsServicesCacheVariables: "Event Orchestrations Services Cache Variables",
};

export const TOOLTIP_TEXT = {
  services:
    "Select Technical Service(s) in order to add them to the Terraformation Plan.",
  servicesOrchestrations:
    "Select Service(s) Orchestrations in order to add them to the Terraformation Plan.",
  servicesOrchestrationsCacheVariables:
    "Select Service(s) Orchestrations Cache Variables in order to add them to the Terraformation Plan.",
  users: "Select User(s) in order to add them to the Terraformation Plan.",
  orchestrations:
    "Select Event Orchestration(s) in order to add them to the Terraformation Plan.",
  orchestrationsIntegrations:
    "Select Event Orchestration Integration(s) in order to add them to the Terraformation Plan.",
  orchestrationsGlobal:
    "Select Global Event Orchestration(s) in order to add them to the Terraformation Plan.",
  orchestrationsGlobalCacheVariables:
    "Select Global Event Orchestration Cache Variable(s) in order to add them to the Terraformation Plan.",
  orchestrationsRouter:
    "Select Event Orchestration(s) Routers in order to add them to the Terraformation Plan.",
  orchestrationsUnroutedRouter:
    "Select Event Orchestration(s) Unrouteds Router in order to add them to the Terraformation Plan.",
  orchestrationsServices:
    "Select Event Orchestration(s) Services in order to add them to the Terraformation Plan.",
  orchestrationsServicesCacheVariables:
    "Select Event Orchestration(s) Services Cache Variables in order to add them to the Terraformation Plan.",
};

/** @param {Object} props
 * @param {import("./index").TFerReducerState} props.tferState
 * @param {import("./index").TFerDispatchSetPlan} props.setPlan
 */
export const ResourcesPicker = ({ tferState, setPlan }) => {
  const users = useResource("users", tferState.resources.users, setPlan);
  const services = useResource(
    "services",
    tferState.resources.services,
    setPlan,
  );
  const servicesOrchestrations = useResource(
    "servicesOrchestrations",
    tferState.resources.servicesOrchestrations,
    setPlan,
  );
  const servicesOrchestrationsCacheVariables = useResource(
    "servicesOrchestrationsCacheVariables",
    tferState.resources.servicesOrchestrationsCacheVariables,
    setPlan,
  );
  const orchestrations = useResource(
    "orchestrations",
    tferState.resources.orchestrations,
    setPlan,
  );
  const orchestrationsIntegrations = useResource(
    "orchestrationsIntegrations",
    tferState.resources.orchestrationsIntegrations,
    setPlan,
  );
  const orchestrationsGlobal = useResource(
    "orchestrationsGlobal",
    tferState.resources.orchestrationsGlobal,
    setPlan,
  );
  const orchestrationsGlobalCacheVariables = useResource(
    "orchestrationsGlobalCacheVariables",
    tferState.resources.orchestrationsGlobalCacheVariables,
    setPlan,
  );
  const orchestrationsRouter = useResource(
    "orchestrationsRouter",
    tferState.resources.orchestrationsRouter,
    setPlan,
  );
  const orchestrationsUnroutedRouter = useResource(
    "orchestrationsUnroutedRouter",
    tferState.resources.orchestrationsUnroutedRouter,
    setPlan,
  );

  return (
    <div>
      <div className="tfer-app__resources-picker">
        <MultiCheckbox {...services} />
        <MultiCheckbox {...users} />
        <MultiCheckbox {...orchestrations} />
        <MultiCheckbox {...orchestrationsIntegrations} />
        <MultiCheckbox {...orchestrationsRouter} />
        <MultiCheckbox {...orchestrationsGlobal} />
        <MultiCheckbox {...servicesOrchestrations} />
        <MultiCheckbox {...orchestrationsGlobalCacheVariables} />
        <MultiCheckbox {...servicesOrchestrationsCacheVariables} />
        <MultiCheckbox {...orchestrationsUnroutedRouter} />
      </div>
    </div>
  );
};

const OPTION_VALUE_FN = {};
const OPTION_VALUE_DEFAULT_FN = (item) => item.id;

const OPTION_LABEL_FN = {};
const OPTION_LABEL_DEFAULT_FN = (item) => item.summary;

const toOption = (key, list) =>
  (list || []).map((item) => {
    let valueFn = OPTION_VALUE_FN[key];
    if (typeof valueFn !== "function") {
      valueFn = OPTION_VALUE_DEFAULT_FN;
    }
    let labelFn = OPTION_LABEL_FN[key];
    if (typeof labelFn !== "function") {
      labelFn = OPTION_LABEL_DEFAULT_FN;
    }
    return { value: valueFn(item), label: labelFn(item) };
  });

/**
 * @param {string} key
 * @param {import("./index").TFerAsyncAction} action
 * @param {import("./index").TFerDispatchSetPlan} setPlan
 */
const useResource = (key, action, setPlan) => {
  if (action == null) action = {};
  const [checked, setChecked] = useState([]);
  const options = useMemo(() => toOption(key, action.data), [key, action.data]);
  return {
    label: TITLE_TEXT[key],
    disabled: (action.data?.length || 0) > 0 && action.status !== "success",
    checked,
    options,
    onToggleCheckbox: (value) => {
      setChecked((prevState) => {
        const ix = prevState.indexOf(value);
        let newState = [];
        if (ix >= 0) {
          newState = [...prevState.slice(0, ix), ...prevState.slice(ix + 1)];
        } else {
          newState = [...prevState, value];
        }
        setPlan(key)(
          (action.data || []).filter((item) => newState.includes(item.id)),
        );
        return newState;
      });
    },
    pending: action.status === "pending" || action.status === "idle",
    // tooltip: TOOLTIP_TEXT[key],
  };
};

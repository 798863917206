import { useMemo, useState } from "react";
import { faGears } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const TITLE_TEXT = {
  services: "Technical services",
  servicesOrchestrations: "Services Orchestrations",
  servicesOrchestrationsCacheVariables: "Services Orchestrations Cache Variables",
  users: "Users",
  orchestrations: "Event Orchestrations",
  orchestrationsIntegrations: "Integrations for an Event Orchestration",
  orchestrationsGlobal: "Global Event Orchestrations",
  orchestrationsGlobalCacheVariables: "Global Event Orchestrations Cache Variables",
  orchestrationsRouter: "Event Orchestration Routers",
  orchestrationsUnroutedRouter: "Event Orchestrations Unrouted Routers",
  orchestrationsServices: "Event Orchestrations Services",
  orchestrationsServicesCacheVariables: "Event Orchestrations Services Cache Variables",
};

/** @param {Object} props
 * @param {import("./index").TFerReducerState} props.tferState
 * @param {import("./index").RequestTerraformation} props.requestTerraformation
 * @param {() => void} props.requestTerraformation
 */
export const TerraformationPlan = ({
  tferState,
  customerSubdomain,
  requestTerraformation,
}) => {
  const terraformationMode = tferState.terraformationMode;
  const [requestTerraformationPending, setRequestTerraformationPending] =
    useState(false);

  const sizeOfPlan = (state) => {
    return (
      state.terraformationPlan.services.length +
      state.terraformationPlan.servicesOrchestrations.length +
      state.terraformationPlan.servicesOrchestrationsCacheVariables.length +
      state.terraformationPlan.users.length +
      state.terraformationPlan.orchestrations.length +
      state.terraformationPlan.orchestrationsIntegrations.length +
      state.terraformationPlan.orchestrationsGlobal.length +
      state.terraformationPlan.orchestrationsGlobalCacheVariables.length +
      state.terraformationPlan.orchestrationsRouter.length +
      state.terraformationPlan.orchestrationsUnroutedRouter.length +
      state.terraformationPlan.orchestrationsServices.length +
      state.terraformationPlan.orchestrationsServicesCacheVariables.length
    );
  };

  const isPlanEmpty = useMemo(() => {
    return sizeOfPlan(tferState) === 0;
  }, [tferState]);

  const renderTerraformEverything = () => {
    return (
      <div>
        <h5>
          Terraform configuration code will be generated for the subdomain{" "}
          <code className="tfer-keyword">{customerSubdomain}</code> extracting
          all PagerDuty resources of the following types:
        </h5>
        <div className="tfer-app__show-plan-list-container">
          <ul>
            <li>{TITLE_TEXT.services}</li>
            <li>{TITLE_TEXT.users}</li>
            <li>{TITLE_TEXT.orchestrations}</li>
            <li>{TITLE_TEXT.orchestrationsIntegrations}</li>
            <li>{TITLE_TEXT.orchestrationsRouter}</li>
            <li>{TITLE_TEXT.orchestrationsGlobal}</li>
            <li>{TITLE_TEXT.servicesOrchestrations}</li>
            <li>{TITLE_TEXT.orchestrationsGlobalCacheVariables}</li>
            <li>{TITLE_TEXT.servicesOrchestrationsCacheVariables}</li>
            <li>{TITLE_TEXT.orchestrationsUnroutedRouter}</li>
          </ul>
        </div>
      </div>
    );
  };

  /**
   * @param {Object} props
   * @param {import("./index").PagerDutyResourceData[]} resources
   */
  const ResourceItems = ({ resources }) =>
    resources?.map((item) => (
      <li key={item.id} className="tf-app__show-plan-sub-item">
        {item.html_url === "" ? (
          item.summary
        ) : (
          <a target="_blank" href={item.html_url}>
            {" "}
            {item.summary}{" "}
          </a>
        )}
      </li>
    ));

  const renderTerraformPlan = () => {

    /**
    * @param {import("./index").TFerTerraformationPlan} resources
    * @param {import("./index").TFerStateResourceType} resourceType
    */
    const renderResourceItems = (resources, resourceType) => {
      if (resources[resourceType].length > 0) {
        return (
          <>
            <li key={resourceType}>{TITLE_TEXT[resourceType]}</li>
            <ResourceItems
              resources={resources[resourceType]}
            />
          </>
        )
      }
    }

    return (
      <div>
        <h5>
          Terraform configuration code will be generated for the subdomain{" "}
          <code className="tfer-keyword">{customerSubdomain}</code> extracting
          the following PagerDuty resources:
        </h5>
        <div className="tfer-app__show-plan-list-container">
          <ul className="tfer-app__show-plan-list">
            {
              Object.keys(tferState.terraformationPlan).map((resourceType) => {
                return renderResourceItems(tferState.terraformationPlan, resourceType)
              })
            }
          </ul>
        </div>
      </div>
    );
  };

  const startTerraformation = async () => {
    const terraformEverything = terraformationMode === "everything";
    const terraformationPlan = [
      ...tferState.terraformationPlan.services,
      ...tferState.terraformationPlan.servicesOrchestrations,
      ...tferState.terraformationPlan.servicesOrchestrationsCacheVariables,
      ...tferState.terraformationPlan.users,
      ...tferState.terraformationPlan.orchestrations,
      ...tferState.terraformationPlan.orchestrationsIntegrations,
      ...tferState.terraformationPlan.orchestrationsGlobal,
      ...tferState.terraformationPlan.orchestrationsGlobalCacheVariables,
      ...tferState.terraformationPlan.orchestrationsRouter,
      ...tferState.terraformationPlan.orchestrationsUnroutedRouter,
      ...tferState.terraformationPlan.orchestrationsServices,
      ...tferState.terraformationPlan.orchestrationsServicesCacheVariables,
    ];
    setRequestTerraformationPending(true);
    await requestTerraformation(terraformEverything, terraformationPlan);
    setRequestTerraformationPending(false);
  };

  return (
    <div className="tfer-app__show-plan-container">
      <div className="tfer-app__plan-btn-container">
        <button
          className="tfer-app__btn tfer-app__btn--generate-tf"
          onClick={() => {
            startTerraformation();
          }}
          disabled={
            (isPlanEmpty && terraformationMode !== "everything") ||
            requestTerraformationPending
          }
        >
          <FontAwesomeIcon icon={faGears} style={{ marginRight: 10 }} />
          Generate Terraform Code
        </button>
      </div>
      {terraformationMode === "plan" &&
        (isPlanEmpty ? (
          <p className="tfer-app--warning-text">
            A terraformation plan must be chosen, either by selecting the
            PagerDuty resources to be managed by Terraform, or by selecting
            terraformate everything.
          </p>
        ) : (
          renderTerraformPlan()
        ))}
      {terraformationMode === "everything" && renderTerraformEverything()}
    </div>
  );
};

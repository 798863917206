import { useState, useMemo } from "react";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

/** @param {import("./index").MultiCheckboxProps} props
 */
const MultiCheckbox = ({
  label,
  disabled,
  checked,
  options,
  onToggleCheckbox,
  pending,
}) => {
  const [search, setSearch] = useState("");
  // Options displayed in the selector
  const filtered = useMemo(
    () =>
      options.filter((o) =>
        o.label?.toLowerCase().includes(search.toLowerCase()),
      ),
    [search, options],
  );

  const allSelected = options.length > 0 && checked.length === options.length;

  const handleToggleCheckbox = (value) => (ev) => {
    if (disabled) {
      return;
    }
    ev.stopPropagation();
    onToggleCheckbox(value);
  };

  const handleSelectAll = (ev) => {
    if (disabled) {
      return;
    }
    ev.stopPropagation();
    options.forEach((o) => onToggleCheckbox(o.value));
  };

  return (
    <div className="container__filterAlerts">
      <div className="dropdown__clients">
        <div className="container__tooltipMultiCheckbox">
          <div className="label__inputFilter">
            {label}
        {pending && (
          <>
            {" "}
            <FontAwesomeIcon className="tfer-app__spin" icon={faSpinner} />
          </>
        )}
          </div>
          <label className="tfer-app__select-all">
            <input
              type="checkbox"
              checked={allSelected}
              onChange={handleSelectAll}
            />{" "}
            Select all
          </label>
        </div>

        <div className="dropdown-menuMyAlerts__wrap">
          <input
            className="search__subdomainHomeFilter"
            type="text"
            placeholder="Search..."
            value={search}
            onChange={(ev) => setSearch(ev.target.value)}
          />
          <div className="dropdown-menuMyAlerts">
            <ul>
              {
                (filtered?.length || 0) > 0 &&
                filtered.map((item) => (
                  <li key={item.value}>
                    <label>
                      <input
                        type="checkbox"
                        checked={checked.includes(item.value)}
                        onChange={handleToggleCheckbox(item.value)}
                        disabled={disabled}
                      />
                      <span className="customer_name">{item.label}</span>
                    </label>
                  </li>
                ))
              }
              {options.length === 0 && !pending && (
                <p style={{ color: "#afafaf" }}>No items</p>
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MultiCheckbox;

import React, { useState, useEffect, useReducer } from "react";
import "./Terraformer.css";
import { apiRequest, apiRoutes } from "../../services";
import Modal from "../../components/Modal";
import { AccountPicker } from "./AccountPicker";
import { ResourcesPicker } from "./ResourcesPicker";
import { TerraformationPlan } from "./TerraformationPlan";
import {
  faListCheck,
  faRightLeft,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TerraformationProgress } from "./TerraformationProgress";
import Copyright from "../../components/Copyright";
import Questions from "../../components/Questions";
import Jwt from "../../utils/jwt";

/** @type import("./index").AsyncAction */
const asyncActionDefault = {
  status: "idle",
  data: [],
  error: null,
};

const TFER_ACTIONS = {
  FETCH_FAQS: "FETCH_FAQS",
  FETCH_FAQS_SUCCESS: "FETCH_FAQS_SUCCESS",
  FETCH_FAQS_ERROR: "FETCH_FAQS_ERROR",

  FETCH_CLIENTS: "FETCH_CLIENTS",
  FETCH_CLIENTS_SUCCESS: "FETCH_CLIENTS_SUCCESS",
  FETCH_CLIENTS_ERROR: "FETCH_CLIENTS_ERROR",

  FETCH_CLIENT_SUBDOMAINS: "FETCH_CLIENT_SUBDOMAINS",
  FETCH_CLIENT_SUBDOMAINS_SUCCESS: "FETCH_CLIENT_SUBDOMAINS_SUCCESS",
  FETCH_CLIENT_SUBDOMAINS_ERROR: "FETCH_CLIENT_SUBDOMAINS_ERROR",

  FETCH_CUSTOMERS: "FETCH_CUSTOMERS",
  FETCH_CUSTOMERS_SUCCESS: "FETCH_CUSTOMERS_SUCCESS",
  FETCH_CUSTOMERS_ERROR: "FETCH_CUSTOMERS_ERROR",

  FETCH_RESOURCES: "FETCH_RESOURCES",
  FETCH_RESOURCES_SUCCESS: "FETCH_RESOURCES_SUCCESS",
  FETCH_RESOURCES_ERROR: "FETCH_RESOURCES_ERROR",

  SET_TERRAFORMATION_PLAN: "SET_TERRAFORMATION_PLAN",
  RESET_TERRAFORMATION_PLAN: "RESET_TERRAFORMATION_PLAN",
  SET_TERRAFORMATION_MODE: "SET_TERRAFORMATION_MODE",
  RESET_STATE: "RESET_STATE",
};

const UI_PROGRESS_STATE = {
  RUN_INITIAL_CHECKS: "RUN_INITIAL_CHECKS",
  ASK_FOR_API_CREDENTIALS: "ASK_FOR_API_CREDENTIALS",
  DISPLAY_ACCOUNT_PICKER: "DISPLAY_ACCOUNT_PICKER",
  DISPLAY_RESOURCES_PICKER: "DISPLAY_RESOURCES_PICKER",
  DISPLAY_TERRAFORMATION_PLAN: "DISPLAY_TERRAFORMATION_PLAN",
  DISPLAY_TERRAFORMATION_PROGRESS: "DISPLAY_TERRAFORMATION_PROGRESS",
  DISPLAY_TERRAFORMATION_RESULTS: "DISPLAY_TERRAFORMATION_RESULTS",
};

const blockingErrorCommonMessage =
  "Error while fetching functional application data. We are working on it. Please try again later.";
const blockingErrorMessages = {
  fetching_customers: blockingErrorCommonMessage,
  fetching_resources_data: blockingErrorCommonMessage,
  fetching_customer_api_credentials_status: blockingErrorCommonMessage,
};

const TAB_TITLES = {
  RUN_INITIAL_CHECKS: "Loading...",
  ASK_FOR_API_CREDENTIALS: "Upload your API credentials",
  DISPLAY_ACCOUNT_PICKER: "PagerDuty customer",
  DISPLAY_RESOURCES_PICKER: "Select resources by type or multi-select",
  DISPLAY_TERRAFORMATION_PLAN: "Terrafomation Plan",
  DISPLAY_TERRAFORMATION_PROGRESS: "Terrafomation in Progress",
  DISPLAY_TERRAFORMATION_RESULTS: "Results",
};

/** @returns {import("./index").TFerReducerState} */
function createInitialState() {
  return {
    terraformationMode: null,
    resources: {
      services: {
        ...asyncActionDefault,
      },
      servicesOrchestrations: {
        ...asyncActionDefault,
      },
      servicesOrchestrationsCacheVariables: {
        ...asyncActionDefault,
      },
      users: {
        ...asyncActionDefault,
      },
      orchestrations: {
        ...asyncActionDefault,
      },
      orchestrationsIntegrations: {
        ...asyncActionDefault,
      },
      orchestrationsGlobal: {
        ...asyncActionDefault,
      },
      orchestrationsGlobalCacheVariables: {
        ...asyncActionDefault,
      },
      orchestrationsRouter: {
        ...asyncActionDefault,
      },
      orchestrationsUnroutedRouter: {
        ...asyncActionDefault,
      },
      orchestrationsServices: {
        ...asyncActionDefault,
      },
      orchestrationsServicesCacheVariables: {
        ...asyncActionDefault,
      },
    },
    terraformationPlan: {
      services: [],
      servicesOrchestrations: [],
      servicesOrchestrationsCacheVariables: [],
      users: [],
      orchestrations: [],
      orchestrationsIntegrations: [],
      orchestrationsGlobal: [],
      orchestrationsGlobalCacheVariables: [],
      orchestrationsRouter: [],
      orchestrationsUnroutedRouter: [],
      orchestrationsServices: [],
      orchestrationsServicesCacheVariables: [],
    },
    clients: {
      ...asyncActionDefault,
    },
    clientsSubdomains: {},
    customers: {
      ...asyncActionDefault,
    },
    questions: {
      ...asyncActionDefault,
    },
    isPremiumTerraformer: Jwt.getItem("all")?.claims?.roles?.some((role) => {
      if (role.role === "Admin") {
        return true;
      }
      const hasTerraformerWrite = role.apps.find(
        (a) => a.app === "TerraformerApp" && a.permissions.includes("WRITE"),
      );
      return role.role === "Premium Csg Member" && hasTerraformerWrite;
    }),
  };
}

/** @param {import("./index").TFerReducerState} state
 * @param {import("./index").TFerAction} action
 * @returns {import("./index").TFerReducerState}
 */
function tferReducer(state, action) {
  switch (action.type) {
    case TFER_ACTIONS.SET_TERRAFORMATION_MODE:
      return {
        ...state,
        terraformationMode: action.data,
      };
    case TFER_ACTIONS.SET_TERRAFORMATION_PLAN:
      return reducerSetTerraformationPlan(
        state,
        action.data.key,
        action.data.update,
      );

    case TFER_ACTIONS.FETCH_RESOURCES:
      return {
        ...state,
        resources: {
          ...state.resources,
          [action.resourceType]: {
            ...state.resources[action.resourceType],
            status: "pending",
          },
        },
      };
    case TFER_ACTIONS.FETCH_RESOURCES_SUCCESS:
      return {
        ...state,
        resources: {
          ...state.resources,
          [action.resourceType]: {
            status: "success",
            data: action.data,
          },
        },
      };
    case TFER_ACTIONS.FETCH_RESOURCES_ERROR:
      return {
        ...state,
        resources: {
          ...state.resources,
          [action.resourceType]: {
            status: "error",
            error: action.error,
          },
        },
      };

    case TFER_ACTIONS.FETCH_FAQS:
      return {
        ...state,
        questions: {
          status: "pending",
          error: null,
          data: [],
        },
      };
    case TFER_ACTIONS.FETCH_FAQS_SUCCESS:
      return {
        ...state,
        questions: {
          status: "success",
          data: action.data,
          error: null,
        },
      };
    case TFER_ACTIONS.FETCH_FAQS_ERROR:
      return {
        ...state,
        questions: {
          status: "error",
          error: action.error,
          data: [],
        },
      };

    case TFER_ACTIONS.FETCH_CUSTOMERS:
      return {
        ...state,
        customers: {
          status: "pending",
          error: null,
          data: [],
        },
      };
    case TFER_ACTIONS.FETCH_CUSTOMERS_SUCCESS:
      return {
        ...state,
        customers: {
          status: "success",
          data: action.data,
          error: null,
        },
      };
    case TFER_ACTIONS.FETCH_CUSTOMERS_ERROR:
      return {
        ...state,
        customers: {
          status: "error",
          error: action.error,
          data: [],
        },
      };

    case TFER_ACTIONS.FETCH_CLIENT_SUBDOMAINS:
      return {
        ...state,
        clientsSubdomains: {
          ...state.clientsSubdomains,
          [action.client.salesforce_id]: {
            status: "pending",
            data: [],
            error: null,
          },
        },
      };
    case TFER_ACTIONS.FETCH_CLIENT_SUBDOMAINS_SUCCESS:
      return {
        ...state,
        clientsSubdomains: {
          ...state.clientsSubdomains,
          [action.client.salesforce_id]: {
            status: "success",
            data: action.data,
            error: null,
          },
        },
      };
    case TFER_ACTIONS.FETCH_CLIENT_SUBDOMAINS_SUCCESS:
      return {
        ...state,
        clientsSubdomains: {
          ...state.clientsSubdomains,
          [action.client.salesforce_id]: {
            status: "success",
            data: action.data,
            error: null,
          },
        },
      };

    case TFER_ACTIONS.FETCH_CLIENTS:
      return {
        ...state,
        clients: {
          status: "pending",
          error: null,
          data: [],
        },
      };
    case TFER_ACTIONS.FETCH_CLIENTS_SUCCESS:
      // Some items in the API response come with the same salesforce_id on
      // different regions, we will list them once.
      const memo = {};
      return {
        ...state,
        clients: {
          status: "success",
          data: action.data
            .filter((item) => {
              const isFirstAppearance = memo[item.salesforce_id] == null;
              memo[item.salesforce_id] = true;
              return isFirstAppearance;
            })
            .sort(
              (a, b) =>
                b.customer_company_name.toLowerCase() -
                a.customer_company_name.toLowerCase(),
            ),
          error: null,
        },
      };
    case TFER_ACTIONS.FETCH_CLIENTS_ERROR:
      return {
        ...state,
        clients: {
          status: "error",
          error: action.error,
          data: [],
        },
      };

    case TFER_ACTIONS.RESET_TERRAFORMATION_PLAN:
      const { terraformationPlan } = createInitialState();
      return { ...state, terraformationPlan };

    case TFER_ACTIONS.RESET_STATE:
      return createInitialState();
    default:
      return state;
  }
}

/** @param {import("./index").TFerReducerState} state
 * @param {string} key
 * @param {import("./index").PagerDutyResourceData[]} update
 */
function reducerSetTerraformationPlan(state, key, update) {
  return {
    ...state,
    terraformationPlan: {
      ...state.terraformationPlan,
      [key]: update,
    },
  };
}

export function Terraformer() {
  const [tabTitle, setTabTitle] = useState(
    TAB_TITLES[UI_PROGRESS_STATE.RUN_INITIAL_CHECKS],
  );
  const [displayUIState, setDisplayUIState] = useState(
    UI_PROGRESS_STATE.RUN_INITIAL_CHECKS,
  );
  const [blockingErrorMessage, setBlockingErrorMessage] = useState(null);
  const [terraformationRunId, setTerraformationRunId] = useState("");

  /** @type {['normal' | 'switch' | null, function(boolean): void]} */
  const [showTerraformationModeButton, setShowTerraformationModeButton] =
    useState(null);

  /** @type [import("./index").TFerReducerState, import("./index").TFerDispatch] */
  const [tferState, dispatch] = useReducer(
    tferReducer,
    null,
    createInitialState,
  );

  const [customerSubdomain, setCustomerSubdomain] = useState("");
  const [customerCompanyName, setCustomerCompanyName] = useState("");
  const [hasRESTAPIKey, setHasRESTAPIKey] = useState(null);
  const [hasUserAPIKey, setHasUserAPIKey] = useState(null);
  const [modalBlockingErrorOpen, setBlockingModalErrorOpen] = useState(false);
  const [selectionModeTab, setSelectionModeTab] = useState(tabTitle);
  const [FAQModalOpen, setFAQModalOpen] = useState(false);
  const [apiCredsValidationError, setAPICredsValidationError] = useState("");

  const getCustomerAPICredentialsStatus = async (customer_subdomain) => {
    const [error, data] = await apiRequest({
      method: "get",
      url: `${apiRoutes.tferAppCustomersAPICredentialsStatus}?customer_subdomain=${customer_subdomain}`,
    });
    if (error || !data || !data.status) {
      console.error({
        error_Terraformer_getCustomerAPICredentialsStatus: error,
      });
      return setBlockingErrorMessage(
        blockingErrorMessages.fetching_customer_api_credentials_status,
      );
    }

    /** @type {import("./index").CustomerAPICredentialsStatus} */
    const status = data.status;

    if (data.status.has_rest_api_key != null) {
      setHasRESTAPIKey(status.has_rest_api_key);
    }
    if (data.status.has_user_api_key != null) {
      setHasUserAPIKey(status.has_user_api_key);
    }
  };

  const createAPICredentials = async (apiCredential, customerSubdomain) => {
    const [error] = await apiRequest({
      method: "post",
      url: `${apiRoutes.tferAppAPICredentials}?customer_subdomain=${customerSubdomain}`,
      data: {
        rest_api_key: apiCredential,
        customer_subdomain: customerSubdomain,
      },
    });
    if (error && error.status === 400) {
      return setAPICredsValidationError("Invalid API key provided");
    }
    if (error) {
      console.error({
        error_Terraformer_createAPICredentials: error,
      });
      return setBlockingErrorMessage(
        blockingErrorMessages.fetching_customer_api_credentials_status,
      );
    }
    setAPICredsValidationError("");
  };

  /** @returns {Promise<[Error, import("./index").Customer[]]>} */
  const getCustomers = async () => {
    const [error, data] = await apiRequest({
      method: "get",
      url: `${apiRoutes.tferAppCustomers}`,
    });
    if (error) {
      return [error, null];
    }
    const customers = data.customers;
    return [null, customers];
  };

  const createCustomer = async (customerCompanyName, customerSubdomain) => {
    const [error] = await apiRequest({
      method: "post",
      url: `${apiRoutes.tferAppCustomers}?customer_subdomain=${customerSubdomain}`,
      data: {
        customer_company_name: customerCompanyName,
        customer_subdomain: customerSubdomain,
      },
    });
    // Ignore 409 errors, it means the customer already exists
    if (error && error.status !== 409) {
      console.error({
        error_Terraformer_createCustomer: error,
      });
      return setBlockingErrorMessage(blockingErrorMessages.fetching_customers);
    }
  };

  /** @returns {Promise<[Error, import("./index").Customer[]]>} */
  const getClients = async () => {
    const [error, data] = await apiRequest({
      method: "get",
      url: apiRoutes.getClients,
    });
    if (error) {
      return [error, null];
    }
    const customers = data?.data?.profiles;
    return [null, customers];
  };

  /**
   * @param {string} path
   * @param {string} [key]
   * @param {string[]} [ids]
   * @returns {Promise<[Error, import("./index").PagerDutyResourceData[]]>} */
  const getResourceWithType = async (path, key, ids) => {
    let url = `${apiRoutes.tferApp}/${path}?customer_subdomain=${customerSubdomain}`;
    if (Array.isArray(ids)) {
      url += `&ids=${ids.join(",")}`;
    }
    const [error, resp] = await apiRequest({ method: "get", url });
    if (error || !resp) {
      return [error, null];
    }

    /** @type import("./index").PagerDutyResourceData[] */
    const data = resp[key == null ? path : key];
    return [null, data];
  };

  /** @returns {Promise<[Error, import("./index").FAQ[]]>} */
  const getFAQs = async () => {
    const [error, data] = await apiRequest({
      method: "get",
      url: `${apiRoutes.tferAppFAQs}`,
    });
    if (error || !data) {
      return [error, null];
    }

    return [null, data.faqs];
  };

  /** @type {import("./index").RequestTerraformation} */
  const requestTerraformation = async (everything, plan) => {
    const [error, data] = await apiRequest({
      method: "post",
      url: `${apiRoutes.tferAppTerraformations}?customer_subdomain=${customerSubdomain}`,
      data: {
        everything,
        plan: everything ? null : { resources: plan },
      },
    });
    if (error || data.run_id === "") {
      console.error({ error_Terraformer_requestTerraformation: error });
      return setBlockingErrorMessage(blockingErrorCommonMessage);
    }

    setTerraformationRunId(data.run_id);
    setDisplayUIState(UI_PROGRESS_STATE.DISPLAY_TERRAFORMATION_PROGRESS);
  };

  /** @type {import("./index").GetTerraformationStatus} */
  const getTerraformationStatus = async (runId) => {
    const [error, data, response] = await apiRequest({
      method: "get",
      url: `${apiRoutes.tferAppTerraformations}/${runId}?customer_subdomain=${customerSubdomain}`,
    });

    if (response.status === 404) {
      console.error({ error_Terraformer_getTerraformationStatus: error });
      setBlockingErrorMessage(blockingErrorCommonMessage);
      return "not_found";
    }
    if (response.status === 500) {
      console.error({ error_Terraformer_getTerraformationStatus: error });
      setBlockingErrorMessage(blockingErrorCommonMessage);
      return "failed";
    }

    if (error || !data) {
      console.error({ error_Terraformer_getTerraformationStatus: error });
      return setBlockingErrorMessage(blockingErrorCommonMessage);
    }

    return data.status;
  };

  /** @type {import("./index").GetTerraformationLogs} */
  const getTerraformationLogs = async (runId) => {
    const [error, data] = await apiRequest({
      method: "get",
      url: `${apiRoutes.tferAppTerraformations}/${runId}/log?customer_subdomain=${customerSubdomain}`,
    });
    if (error) {
      console.error({ error_Terraformer_getTerraformationLogs: error });
      return setBlockingErrorMessage(blockingErrorCommonMessage);
    }

    return data;
  };

  /** @type {import("./index").DownloadTerraformCode} */
  const downloadTerraformCode = async (runId) => {
    const [error, data, response] = await apiRequest({
      method: "get",
      url: `${apiRoutes.tferAppTerraformations}/${runId}/download?customer_subdomain=${customerSubdomain}`,
    });

    if (error || !response?.headers["content-disposition"]) {
      console.error({ error_Terraformer_downloadTerraformCode: error });
      return setBlockingErrorMessage(blockingErrorCommonMessage);
    }

    const filename = response.headers["content-disposition"]
      .split(";")[1]
      .split("=")[1]
      .replace(/^"|"$/g, "");
    const contentType = response.headers["content-type"];
    const bytes = Uint8Array.from(atob(data), (c) => c.charCodeAt(0));
    var blob = new Blob([bytes], { type: contentType });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    a.remove();
  };

  /*
   * Declare all dispatcher/selectors here
   */

  /**
   * @param {import("./index").TFerStateResourceType} resourceType
   * @param {string} path
   * @param {string} [key]
   * @param {string[]} [ids]
   * @param {(data: import("./index").PagerDutyResourceData[]) => import("./index").PagerDutyResourceData[]} [dataPatchingFn] - Function to patch the data before dispatching
   * @returns {Promise<void>}
   */
  const dispatchGetResources = async (resourceType, path, key, ids, dataPatchingFn) => {
    dispatch({ type: TFER_ACTIONS.FETCH_RESOURCES, resourceType });

    const [error, data] = await getResourceWithType(path, key, ids);
    if (error) {
      console.error({ error_Terraformer_dispatchGetResources: error, resourceType, path });
      return dispatch({
        type: TFER_ACTIONS.FETCH_RESOURCES_ERROR,
        resourceType,
        error,
      });
    }

    let patchedData = data;
    if (data && dataPatchingFn) {
      patchedData = dataPatchingFn(data);
    }

    if (data) {
      dispatch({
        type: TFER_ACTIONS.FETCH_RESOURCES_SUCCESS,
        resourceType,
        data: patchedData,
      });
    }
  };

  const dispatchGetFAQs = async () => {
    dispatch({ type: TFER_ACTIONS.FETCH_FAQS });
    const [error, res] = await getFAQs();
    if (error) {
      console.error({ error_Terraformer_dispatchGetFAQs: error });
      return dispatch({ type: TFER_ACTIONS.FETCH_FAQS_ERROR, error: error });
    }
    if (res) {
      const faqs = res;
      dispatch({ type: TFER_ACTIONS.FETCH_FAQS_SUCCESS, data: faqs });
    }
  };

  const dispatchGetClients = async () => {
    dispatch({ type: TFER_ACTIONS.FETCH_CLIENTS });
    const [error, res] = await getClients();
    if (error) {
      console.error({ error_Terraformer_dispatchGetClients: error });
      setBlockingErrorMessage(blockingErrorMessages.fetching_clients);
      return dispatch({
        type: TFER_ACTIONS.FETCH_CLIENTS_ERROR,
        error: error,
      });
    }
    if (res) {
      const clients = res;
      await dispatch({
        type: TFER_ACTIONS.FETCH_CLIENTS_SUCCESS,
        data: clients,
      });
    }
  };

  const getClientSubdomains = async (client) => {
    const [error, data] = await apiRequest({
      method: "get",
      url: `${apiRoutes.getSubdomain}?customer_company_name=${client.customer_company_name}&salesforce_id=${client.salesforce_id}`,
    });
    return [error, data?.subdomains];
  };

  const dispatchGetClientSubdomains = async (client) => {
    const subdomain = tferState.clientsSubdomains[client.salesforce_id];
    if (subdomain == null || subdomain.status === "error") {
      dispatch({ type: TFER_ACTIONS.FETCH_CLIENT_SUBDOMAINS, client });
      const [error, res] = await getClientSubdomains(client);
      if (error) {
        await dispatch({
          type: TFER_ACTIONS.FETCH_CLIENT_SUBDOMAINS_ERROR,
          error,
          client,
        });
      }
      await dispatch({
        type: TFER_ACTIONS.FETCH_CLIENT_SUBDOMAINS_SUCCESS,
        data: res,
        client,
      });
    }
  };

  const dispatchGetCustomers = async () => {
    dispatch({ type: TFER_ACTIONS.FETCH_CUSTOMERS });
    const [error, res] = await getCustomers();
    if (error) {
      console.error({ error_Terraformer_dispatchGetUsers: error });
      setBlockingErrorMessage(blockingErrorMessages.fetching_customers);
      return dispatch({
        type: TFER_ACTIONS.FETCH_CUSTOMERS_ERROR,
        error: error,
      });
    }
    if (res) {
      const customers = res;
      await dispatch({
        type: TFER_ACTIONS.FETCH_CUSTOMERS_SUCCESS,
        data: customers,
      });
    }
  };

  /** @type {import("./index").TFerDispatchSetPlan} */
  const dispatchSetTerraformationPlan = (key) => (update) => {
    dispatch({
      type: TFER_ACTIONS.SET_TERRAFORMATION_PLAN,
      data: { key, update },
    });
  };

  /** @param {import("./index").TerraformationMode} mode
   * @returns {void}
   */
  const dispatchSetTerraformationMode = (mode) => {
    dispatch({ type: TFER_ACTIONS.SET_TERRAFORMATION_MODE, data: mode });
  };

  /*
   * Declare all helper functions here
   */
  const renderUISubtitleContent = (uiState) => {
    const uiStateContentMap = {
      [UI_PROGRESS_STATE.RUN_INITIAL_CHECKS]: (
        <p>Terraformation App is running initial checks...</p>
      ),
      [UI_PROGRESS_STATE.DISPLAY_RESOURCES_PICKER]: (
        <p>
          Generate the whole Terraform configuration files of your PagerDuty
          resources (<i>Only Services, Users and Event Orchestrations resources, at the moment</i>) by clicking
          on the button <strong>Terraform Everything</strong>, or select the
          specific ones you plan to manage via Terraform from the list below and
          click on <strong>Terraformation Plan</strong>.
        </p>
      ),
      [UI_PROGRESS_STATE.DISPLAY_TERRAFORMATION_PLAN]: (
        <p>
          Terraform configuration files written in{" "}
          <code className="tfer-keyword">HCL</code> will be generated for the
          PagerDuty resources shown below. To confirm this action click on{" "}
          <strong>Generate Terraform Code</strong> button. This is a
          read&#x2011;only operation and it cannot produce changes in your
          PagerDuty account until you approve and apply the files using{" "}
          <code className="tfer-keyword">terraform</code>.
        </p>
      ),
    };
    return uiStateContentMap[uiState];
  };

  const cleanPage = () => {
    setDisplayUIState(UI_PROGRESS_STATE.RUN_INITIAL_CHECKS);
    setCustomerSubdomain("");
    setHasRESTAPIKey(null);
    setHasUserAPIKey(null);
    setBlockingErrorMessage(null);
    setBlockingModalErrorOpen(false);
    setSelectionModeTab(tabTitle);
    setFAQModalOpen(false);
    setTabTitle(tabTitle);
    setTerraformationRunId("");
    setShowTerraformationModeButton(null);
    dispatch({ type: TFER_ACTIONS.RESET_STATE });
  };

  const openModal = () => {
    setBlockingModalErrorOpen(true);
  };

  const handleModal = () => {
    cleanPage();
    setBlockingModalErrorOpen(null);
  };

  /** @param {import("./index").TerraformationMode} mode */
  const displayTerraformationPlan = (mode) => {
    dispatchSetTerraformationMode(mode);
    setDisplayUIState(UI_PROGRESS_STATE.DISPLAY_TERRAFORMATION_PLAN);
  };

  /*
   * Declare all useState/useRef handlers here
   */
  useEffect(() => {
    const uiStateProgressCount = {
      [UI_PROGRESS_STATE.RUN_INITIAL_CHECKS]: 0,
      [UI_PROGRESS_STATE.ASK_FOR_API_CREDENTIALS]: 1,
      [UI_PROGRESS_STATE.DISPLAY_ACCOUNT_PICKER]: 2,
      [UI_PROGRESS_STATE.DISPLAY_RESOURCES_PICKER]: 3,
      [UI_PROGRESS_STATE.DISPLAY_TERRAFORMATION_PLAN]: 4,
      [UI_PROGRESS_STATE.DISPLAY_TERRAFORMATION_PROGRESS]: 5,
      [UI_PROGRESS_STATE.DISPLAY_TERRAFORMATION_RESULTS]: 6,
    };

    if (
      showTerraformationModeButton !== null &&
      uiStateProgressCount[displayUIState] <
      uiStateProgressCount[UI_PROGRESS_STATE.DISPLAY_RESOURCES_PICKER]
    ) {
      setShowTerraformationModeButton(null);
    }

    if (
      uiStateProgressCount[displayUIState] ===
      uiStateProgressCount[UI_PROGRESS_STATE.DISPLAY_RESOURCES_PICKER]
    ) {
      setShowTerraformationModeButton("normal");
    }

    if (
      uiStateProgressCount[displayUIState] >=
      uiStateProgressCount[UI_PROGRESS_STATE.DISPLAY_TERRAFORMATION_PLAN]
    ) {
      setShowTerraformationModeButton("switch");
    }
  }, [displayUIState, tferState.terraformationMode]);

  useEffect(() => {
    const isClientsFetched =
      tferState.clients.status !== "idle" ||
      tferState.clients.status !== "pending";
    if (isClientsFetched && tferState.customers.status === "success") {
      setDisplayUIState(UI_PROGRESS_STATE.DISPLAY_ACCOUNT_PICKER);
    }
  }, [tferState.clients, tferState.customers]);

  useEffect(() => {
    if (tferState.questions.status === "idle") {
      dispatchGetFAQs();
    }

    if (tferState.customers.status === "idle") {
      dispatchGetCustomers();
    }

    if (tferState.clients.status === "idle" && tferState.isPremiumTerraformer) {
      dispatchGetClients();
    }

    if (customerSubdomain && hasRESTAPIKey) {
      setDisplayUIState(UI_PROGRESS_STATE.DISPLAY_RESOURCES_PICKER);
      return;
    }
  }, [customerSubdomain, hasRESTAPIKey, hasUserAPIKey]);

  useEffect(() => {
    if (blockingErrorMessage) {
      openModal();
    }
  }, [blockingErrorMessage]);

  useEffect(() => {
    setTabTitle(TAB_TITLES[displayUIState]);
    setSelectionModeTab(TAB_TITLES[displayUIState]);

    if (displayUIState === UI_PROGRESS_STATE.DISPLAY_RESOURCES_PICKER) {
      if (tferState.resources.services.status === "idle") {
        dispatchGetResources("services", "services");
      }
      if (tferState.resources.users.status === "idle") {
        dispatchGetResources("users", "users");
      }
      if (tferState.resources.orchestrations.status === "idle") {
        dispatchGetResources("orchestrations", "orchestrations");
      }
    }
  }, [displayUIState]);

  useEffect(() => {
    if (tferState.resources.services.status === "success") {
      const ids = tferState.resources.services.data.map(item => item.id)
      const servicesStateMap = tferState.resources.services.data.reduce((result, service) => {
        return {
          ...result,
          [service.id]: service,
        }
      }, {})
      const addSummaryToservices = (data) => {
        return data.map((item, idx) => {
          return {
            ...item,
            summary: servicesStateMap[item.id]?.summary,
            html_url: `https://${customerSubdomain}.pagerduty.com/event-orchestration/service/${ids[idx]}`,
          }
        })
      }

      dispatchGetResources(
        "servicesOrchestrations",
        "orchestrations/services",
        "orchestrations_services",
        ids,
        addSummaryToservices,
      );
      dispatchGetResources("servicesOrchestrationsCacheVariables", "orchestrations/services/cache-variables", "orchestrations_service_cache_variables", ids);
    }
  }, [tferState.resources.services.status])

  useEffect(() => {
    if (tferState.resources.orchestrations.status === "success") {
      const ids = tferState.resources.orchestrations.data.map(item => item.id)
      const orchestrationsStateMap = tferState.resources.orchestrations.data.reduce((result, orchestration) => {
        return {
          ...result,
          [orchestration.id]: orchestration,
        }
      }, {})
      const addSummaryToOrchestrations = (data) => {
        return data.map(item => {
          return {
            ...item,
            summary: orchestrationsStateMap[item.id]?.summary,
          }
        })
      }

      dispatchGetResources("orchestrationsIntegrations", "orchestrations/integrations", "orchestration_integrations", ids);
      dispatchGetResources(
        "orchestrationsGlobal",
        "orchestrations/global",
        "orchestrations_global",
        ids,
        addSummaryToOrchestrations,
      );
      dispatchGetResources("orchestrationsGlobalCacheVariables", "orchestrations/global/cache-variables", "orchestrations_global_cache_variables", ids);
      dispatchGetResources(
        "orchestrationsRouter",
        "orchestrations/router",
        "orchestrations_router",
        ids,
        addSummaryToOrchestrations,
      );
      dispatchGetResources(
        "orchestrationsUnroutedRouter",
        "orchestrations/unrouted",
        "orchestrations_unrouted",
        ids,
        addSummaryToOrchestrations,
      );
    }
  }, [tferState.resources.orchestrations.status])

  return (
    <>
      <div id="tfer-app__container">
        <div className="tfer-app__main">
          <div id="tfer-app__title">
            {tferState.questions.status === "success" &&
              tferState.questions.data.length > 0 && (
                <div id="tfer-app__faq" onClick={() => setFAQModalOpen(true)}>
                  Frequently Asked Questions
                  <FontAwesomeIcon
                    id="PSStatus-FAQIcon"
                    icon={faQuestionCircle}
                  />
                </div>
              )}
            <h2 id="SuccessOnDemand-title">Terraformations Factory</h2>
            {customerSubdomain &&
              (
                <h3 className="tfer-app__customer-info">
                  Company: <strong>{customerCompanyName}</strong> / Subdomain: <strong>{customerSubdomain}</strong>
                </h3>
              )}
          </div>

          <div id="tfer-app__sub-title">
            {renderUISubtitleContent(displayUIState)}
          </div>

          <div id="tfer-app__terraformation-mode">
            <div id="tfer-app__terraformation-mode-container">
              <div>
                {displayUIState >=
                  UI_PROGRESS_STATE.DISPLAY_RESOURCES_PICKER && (
                    <button
                      className="tfer-app__btn"
                      title="Change customer subdomain"
                      onClick={cleanPage}
                    >
                      <FontAwesomeIcon icon={faRightLeft} />
                    </button>
                  )}

                {(displayUIState ===
                  UI_PROGRESS_STATE.DISPLAY_TERRAFORMATION_PLAN ||
                  displayUIState ===
                  UI_PROGRESS_STATE.DISPLAY_TERRAFORMATION_PROGRESS ||
                  displayUIState ===
                  UI_PROGRESS_STATE.DISPLAY_TERRAFORMATION_RESULTS) && (
                    <button
                      className="tfer-app__btn"
                      onClick={() => {
                        dispatch({
                          type: TFER_ACTIONS.RESET_TERRAFORMATION_PLAN,
                        });
                        setDisplayUIState(
                          UI_PROGRESS_STATE.DISPLAY_RESOURCES_PICKER,
                        );
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faListCheck}
                        style={{ marginRight: 10 }}
                      />
                      Back to Resources selection
                    </button>
                  )}
              </div>

              <div>
                {showTerraformationModeButton === "normal" && (
                  <>
                    <button
                      className="tfer-app__btn"
                      onClick={() => {
                        displayTerraformationPlan("everything");
                      }}
                    >
                      Terraformate Everything
                    </button>
                    <button
                      className="tfer-app__btn tfer-app__btn--featured"
                      onClick={() => {
                        displayTerraformationPlan("plan");
                      }}
                    >
                      Terraformate by Plan
                    </button>
                  </>
                )}

                {showTerraformationModeButton === "switch" && (
                  <div className="pills-container">
                    <ul className="pills-list">
                      <li
                        className={
                          displayUIState ===
                            UI_PROGRESS_STATE.DISPLAY_TERRAFORMATION_PLAN &&
                            tferState.terraformationMode === "everything"
                            ? "pill-list-item-active"
                            : "pill-list-item"
                        }
                      >
                        Terraformate Everything
                      </li>
                      <li
                        className={
                          displayUIState ===
                            UI_PROGRESS_STATE.DISPLAY_TERRAFORMATION_PLAN &&
                            tferState.terraformationMode === "plan"
                            ? "pill-list-item-active"
                            : "pill-list-item"
                        }
                      >
                        Terraformate by Plan
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="tfer-app__content-container">
            <div className="tabs-container">
              <ul className="tab-list">
                <li
                  className={
                    selectionModeTab === tabTitle
                      ? "tab-list-item-active"
                      : "tab-list-item"
                  }
                  onClick={() => setSelectionModeTab(tabTitle)}
                >
                  {tabTitle}
                </li>
              </ul>
              <div className="tfer-app__tab-content-container">
                {displayUIState === UI_PROGRESS_STATE.RUN_INITIAL_CHECKS && (
                  <></>
                )}
                {displayUIState ===
                  UI_PROGRESS_STATE.DISPLAY_ACCOUNT_PICKER && (
                    <AccountPicker
                      hasRESTAPIKey={hasRESTAPIKey}
                      validationError={apiCredsValidationError}
                      tferState={tferState}
                      onChangeClient={dispatchGetClientSubdomains}
                      onChangeSubdomain={(subdomain) => {
                        if (subdomain) {
                          getCustomerAPICredentialsStatus(subdomain);
                        }
                      }}
                      onSubmit={async (apiCredential, customerSubdomain, customerCompanyName) => {
                        if (!hasRESTAPIKey) {
                          await createCustomer(
                            customerCompanyName,
                            customerSubdomain,
                          );
                          await createAPICredentials(
                            apiCredential,
                            customerSubdomain,
                          );
                          await getCustomerAPICredentialsStatus(
                            customerSubdomain,
                          );
                        }
                        setCustomerSubdomain(customerSubdomain);
                        setCustomerCompanyName(customerCompanyName);
                      }}
                    />
                  )}
                {displayUIState ===
                  UI_PROGRESS_STATE.ASK_FOR_API_CREDENTIALS && (
                    <>ASK_FOR_API_CREDENTIALS</>
                  )}
                {displayUIState ===
                  UI_PROGRESS_STATE.DISPLAY_RESOURCES_PICKER && (
                    <ResourcesPicker
                      tferState={tferState}
                      setPlan={dispatchSetTerraformationPlan}
                    />
                  )}
                {displayUIState ===
                  UI_PROGRESS_STATE.DISPLAY_TERRAFORMATION_PLAN && (
                    <TerraformationPlan
                      tferState={tferState}
                      requestTerraformation={requestTerraformation}
                      customerSubdomain={customerSubdomain}
                    />
                  )}
                {displayUIState ===
                  UI_PROGRESS_STATE.DISPLAY_TERRAFORMATION_PROGRESS && (
                    <TerraformationProgress
                      terraformationRunId={terraformationRunId}
                      getTerraformationLogs={getTerraformationLogs}
                      getTerraformationStatus={getTerraformationStatus}
                      downloadTerraformCode={downloadTerraformCode}
                    />
                  )}
                {displayUIState ===
                  UI_PROGRESS_STATE.DISPLAY_TERRAFORMATION_RESULTS && (
                    <div>DISPLAY_TERRAFORMATION_RESULTS</div>
                  )}
              </div>
            </div>
          </div>
        </div>
        <div className="copyright">
          <Copyright />
        </div>
      </div>
      <>
        <Modal
          show={modalBlockingErrorOpen}
          title="Error"
          handleClose={() => handleModal()}
          modalFitContent
          type="small"
        >
          <div style={{ margin: "20px" }}>
            <p>{blockingErrorMessage}</p>
            <div className="tfer-app--center-content">
              <button
                className="OnBoarding-container-right-button button-regular"
                onClick={() => handleModal()}
              >
                Close and Restart
              </button>
            </div>
          </div>
        </Modal>
        <Modal
          show={FAQModalOpen}
          title="FAQ"
          handleClose={() => setFAQModalOpen(!FAQModalOpen)}
          modalFitContent
        >
          {tferState.questions.data.length > 0 && (
            <Questions questionsArray={tferState.questions.data} />
          )}
        </Modal>
      </>
    </>
  );
}

export class TerraformerErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    // Implement error tracking service call here
    console.error({ error_Terraformer_ErrorBoundary: error, errorInfo });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            flexDirection: "column",
          }}
        >
          <h1>Something went wrong</h1>
          <p>
            We are sorry, but an error occurred while displaying the <strong>Terraformations Factory app</strong> try reloading the page.
          </p>
          <p>
            If the problem persists, please contact us at {" "}
            <a href="https://pagerduty.enterprise.slack.com/archives/CMUHTKHS5" target="_blank">
              <strong>#terraform_provider</strong>
            </a>
            {" "}Slack channel.
          </p>
        </div>
      );
    }

    return this.props.children;
  }
}

export default function TerraformerApp() {
  return (
    <TerraformerErrorBoundary>
      <Terraformer />
    </TerraformerErrorBoundary>
  );
}
